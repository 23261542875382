import React from 'react';
import Layout from '../components/layout';

const RegisterPage = () => {
	
  return(
    <Layout>
		<section className="home-intro">
			<div className="container">
				<div className="row full-height">
					<div className="col-md-7 col-lg-6 col-black">
						<div className="inner d-flex align-items-start flex-column justify-content-center h-100">
							<div className="row">
								<div className="col-md-11 ">
									<h1>Want to get early access and product updates?</h1>
                                    <form className="d-flex">
                                        <input required className="input-register" type='email' placeholder="Enter work address"></input>
                                        <button  className="btn btn-white btn-arrow btn-arrow-white">Register for Beta</button>
                                    </form>
                                    <small className="display d-flex mt-3">Hitting this will trigger a Phasor API. :) <br /> Also, We care about protecting your data. We won't share your email to anyone else</small>
									
									{/* <a href="https://our.status.im/compound-finance/" target="_blank" className="announcement"><span>News</span><b>Compound Finance: Earning Interest with DeFi Lending</b></a> */}
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-4 mt-4 ml-auto col-lg-6 d-flex justify-content-center align-items-center">

					</div>
				</div>
			</div>
		</section>
    </Layout>
)};

export default RegisterPage;
